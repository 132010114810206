import React, { useEffect, useRef, useState } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import SignatureCanvas, { ReactSignatureCanvasProps } from 'react-signature-canvas';

import ClearIcon from '@mui/icons-material/Clear';

import BaseButton from 'components/inputs/buttons/BaseButton';
import BaseDialog from 'components/feedback/BaseDialog';
import ProceedButton from 'components/inputs/buttons/ProceedButton';
import { formatDateTime } from 'helpers/utils/date';

export type FormSignaturePadProps = {
	label?: string;
	name: string;
	component?: React.ReactNode;
	withSignedDate?: boolean;
} & Omit<UseFieldConfig<any>, 'value' | 'type' | 'format'> &
	ReactSignatureCanvasProps;

const SignedDate = ({ signedDate }: { signedDate: string }) => {
	return signedDate ? (
		<div className="text-xs flex flex-col justify-center">
			<div>Date/Time Signed</div>
			<div>{signedDate}</div>
		</div>
	) : (
		<></>
	);
};

const FormSignaturePad = ({ canvasProps, name, validate, label, component, withSignedDate, ...props }: FormSignaturePadProps) => {
	const ref = useRef<SignatureCanvas>(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [signedDate, setSignedDate] = useState('');

	const {
		input: { value, onChange },
		meta: { pristine },
	} = useField(name, { validate });

	const {
		input: { onChange: onChangeSignatureDateTime },
	} = useField(name + 'DateTime');

	const handleProceed = () => {
		onChange(ref.current?.getTrimmedCanvas().toDataURL());
		setOpenDialog(false);
		setSignedDate(formatDateTime(new Date()) ?? '');
	};

	useEffect(() => {
		if (value) {
			setSignedDate(formatDateTime(new Date()) ?? '');
		}
	}, [value]);

	useEffect(() => {
		if (!!value && !!ref.current && pristine) {
			ref.current.fromDataURL(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, ref.current]);

	useEffect(() => {
		if (!withSignedDate) return;
		onChangeSignatureDateTime(signedDate);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signedDate]);

	return (
		<>
			{component ? (
				<div className="cursor-pointer" onClick={() => setOpenDialog(true)}>
					{component}
				</div>
			) : (
				<div className="flex gap-x-3">
					<BaseButton onClick={() => setOpenDialog(true)}>e-Signature</BaseButton>
					<SignedDate signedDate={signedDate} />
				</div>
			)}

			<BaseDialog open={openDialog} onClose={() => setOpenDialog(false)} title={label ?? 'e-Signature'} maxWidth="md" keepMounted>
				<div className="mb-2">Please sign here...</div>
				<div className="relative">
					<SignatureCanvas
						ref={ref}
						canvasProps={{ height: 300, className: 'border border-solid border-gray-300 w-full', ...canvasProps }}
						{...props}
					/>
					<div className="flex gap-x-2 justify-end mt-1">
						<ProceedButton onClick={handleProceed} />

						<BaseButton onClick={() => ref.current?.clear()} startIcon={<ClearIcon />}>
							Clear
						</BaseButton>

						<BaseButton onClick={() => setOpenDialog(false)} startIcon={<ClearIcon />}>
							Cancel
						</BaseButton>
					</div>
				</div>
			</BaseDialog>
		</>
	);
};

export default FormSignaturePad;
