import React from 'react';
import { HTextField, HTextFieldProps } from '@hybrain/mui.ui.inputs.h-text-field';

const BaseTextField: React.FC<HTextFieldProps> = (props) => {
	return (
		<HTextField
			size="small"
			fullWidth
			{...props}
			InputLabelProps={{
				shrink: props.readOnly ? true : undefined,
				...props.InputLabelProps,
			}}
		/>
	);
};

export default BaseTextField;
