import api from 'api';

export const getConsentList = (patientRegID: number) => {
	return api().get(`patientregistry/consent/patient/${patientRegID}`);
};

export const createConsent = (data: any) => {
	return api().post('patientregistry/consent', data);
};

export const viewConsent = (consentID: number) => {
	return api().get(`patientregistry/consent/viewspecific/${consentID}`);
};

export const updateConsent = (consentID: number, data: any) => {
	return api().patch(`patientregistry/consent/${consentID}`, data);
};
