import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import useApi from 'hooks/useApi';
import { parseJSON } from 'helpers/utils/json';
import Consent from 'interfaces/consent.model';
import PatientRegConsent from 'interfaces/patient-reg-consent.model';
import PatientStore from 'modules/private/stores/PatientStore';
import { viewConsent, createConsent, updateConsent } from 'services/consents/consents.service';

interface UseConsentFormProps {
	setForm: (values: any) => void;
	setOpenDialog: (value: boolean) => void;
	printComponent?: React.ReactInstance;
}

const useConsentForm = ({ setForm, setOpenDialog, printComponent }: UseConsentFormProps) => {
	const { state: consentState, pathname } = useLocation() as { state: Consent; pathname: string };
	const [consent, setConsent] = useState<PatientRegConsent>({} as PatientRegConsent);
	const [, setForceRender] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		setForceRender('1');
	}, []);

	const PatientRegConsentID = useMemo(() => {
		if (consentState?.patientreg_consent.length > 0) {
			return consentState.patientreg_consent[0].EHRPatientRegConsentID;
		} else {
			return 0;
		}
	}, [consentState]);

	// * api for view consent
	useApi<PatientRegConsent>(
		{
			action: () => viewConsent(PatientRegConsentID),
			immediate: true,
			onBeforeApiCall() {
				if (!PatientRegConsentID) return false;
			},
			onSuccess({ data }) {
				setForm(parseJSON(data?.response.ConsentData) ?? {});
			},
		},
		[PatientRegConsentID]
	);

	// * api for create consent
	const { callApi: callCreateApi, loading } = useApi<PatientRegConsent>({
		action: createConsent,
		login: true,
		onSuccess({ data }) {
			setOpenDialog(false);
			setConsent(data?.response!);
			navigate(pathname, { state: { ...consentState, patientreg_consent: [data?.response] } });
		},
	});

	// * api for update consent
	const { callApi: callUpdateApi, loading: loadingUpdate } = useApi<PatientRegConsent>({
		action: (values) => updateConsent(consent.EHRPatientRegConsentID, values),
		success: false,
		onSuccess({ data }) {
			setOpenDialog(false);
			setForm(parseJSON(data?.response.ConsentData));
			navigate(pathname, { state: { ...consentState, patientreg_consent: [data?.response] } });
		},
	});

	const handleSubmit = (values: any) => {
		let newValues: PatientRegConsent = {} as PatientRegConsent;
		newValues.isActive = true;
		newValues.ConsentData = JSON.stringify(values);
		newValues.TransactionDate = new Date();
		newValues.CreatedDateTime = new Date();
		newValues.PatientRegID = PatientStore.patient.PatientRegID;
		newValues.EHRMaintenanceConsentID = consentState.EHRMaintenanceConsentID;

		if (consent.EHRPatientRegConsentID) {
			callUpdateApi(newValues);
		} else {
			callCreateApi(newValues);
		}
	};

	const handlePrint = useReactToPrint({
		content: () => {
			return printComponent ?? null;
		},
		pageStyle: '@media print { body { -webkit-print-color-adjust: exact; } @page { margin: 17mm }}',
	});

	useEffect(() => {
		if (consentState?.patientreg_consent.length > 0) {
			setConsent(consentState.patientreg_consent[0]);
		}
	}, [consentState]);

	useEffect(() => {
		if (consent.ConsentData) {
			setForm(parseJSON(consent.ConsentData) ?? {});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consent]);

	return { handleSubmit, handlePrint, loading, loadingUpdate };
};

export default useConsentForm;
