import DateFilterInputGroup from 'components/inputs/DateFilterInputGroup';
import Page from 'components/layout/Page';
import useBreadcrumb from 'hooks/useBreadbrumb';
import React, { Suspense, useState } from 'react';
import SearchPatientDialog from '../Patients/components/SearchPatientDialog';
import useApi from 'hooks/useApi';
import Patient from 'interfaces/patient.model';
import { HTextField } from '@hybrain/mui.ui.inputs.h-text-field';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Outlet } from 'react-router-dom';
import { HLoadingIndicator } from '@hybrain/mui.ui.feedback.h-loading-indicator';

interface RecordsPageProps {}

const RecordsPage: React.FC<RecordsPageProps> = () => {
	useBreadcrumb([{ label: 'Patient Medical History' }]);
	return (
		<Page>
			<Suspense fallback={<HLoadingIndicator />}>
				<div className="mt-3">
					<Outlet />
				</div>
			</Suspense>
		</Page>
	);
};

export default RecordsPage;
