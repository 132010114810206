import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './routes';
import theme from './theme';
import Corporation from 'interfaces/corporation.model';
import { getCorporation } from 'services/maintenance/maintenance.service';
import CorporationStore from 'stores/CorporationStore';
import useApi from 'hooks/useApi';
import SecondaryLoginDialog from 'components/feedback/SecondaryLoginDialog';
import { SecondaryLoginDialogStore } from '@hybrain/mui.hooks.use-api';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { observer } from 'mobx-react-lite';

const SecondaryLoginComponent = observer(() => (
	<SecondaryLoginDialog
		open={SecondaryLoginDialogStore.open}
		onClose={() => SecondaryLoginDialogStore.setOpen(false)}
		loading={SecondaryLoginDialogStore.loading}
		onLoginSuccess={() => {
			SecondaryLoginDialogStore.setLoading(true);
			SecondaryLoginDialogStore.onLoginSuccess(() => {
				SecondaryLoginDialogStore.setLoading(false);
				SecondaryLoginDialogStore.setOpen(false);
			});
		}}
	/>
));

function App() {
	useApi<Corporation[]>({
		action: getCorporation,
		immediate: true,
		onSuccess({ data }) {
			CorporationStore.setCorporation(data?.response?.[0]!);
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<SecondaryLoginComponent />
			<Routes />
		</ThemeProvider>
	);
}

export default App;
