import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import User from 'interfaces/user.model';
import { getFullName } from 'helpers/utils/string';

class AuthStore {
	user: User = {} as User;

	constructor() {
		makeAutoObservable(this);
	}

	setUser({ employee, ...payload }: User) {
		this.user = {
			...payload,
			employee: {
				...employee,
				FullName: getFullName(employee),
			},
		};
	}

	async logout() {
		this.user = {} as User;
		await localforage.clear();
	}
}

const store = new AuthStore();
export default store;
