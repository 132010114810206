import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { dateFormat } from 'helpers/utils/date';
import React from 'react';
import { Label, Table, TableCell } from '../OutPatientComplexPage';
import clsx from 'clsx';
import LetterHead from 'components/print/LetterHead';
import { OutPatientComplexFormPayload } from '../pages/OutPatientComplexForm';

interface OutPatientComplexFormPrintProps {
	reportForm: OutPatientComplexFormPayload;
}

const Text = styled('span', {
	shouldForwardProp: (prop: string) => !['width', 'underline', 'textAlign'].includes(prop),
})<{ width?: number | string; underline?: boolean; textAlign?: 'left' | 'right' | 'center' }>(
	({ width, underline, textAlign }) => ({
		padding: '0 1mm',
		borderBottom: underline ? '1px solid black' : undefined,
		width: width ?? '100%',
		flexShrink: 0,
		textAlign,
	})
);

const Grid = styled('div')({
	display: 'grid',
	gap: '1mm',
	gridTemplateColumns: '45fr 20fr 35fr',
	gridTemplateRows: '1fr 1fr 1fr',
});

interface TextFieldProps {
	label?: string;
	content?: React.ReactNode;
	width?: number | string;
	className?: string;
	underline?: boolean;
	textAlign?: 'left' | 'right' | 'center';
}

const TextField = ({ label, content, width, className, underline, textAlign }: TextFieldProps) => {
	return (
		<div className={clsx(className, 'gap-2mm')}>
			<Label>{label}</Label>
			<Text width={width} underline={underline} textAlign={textAlign}>
				{content}
			</Text>
		</div>
	);
};

const OutPatientComplexFormPrint = ({ reportForm }: OutPatientComplexFormPrintProps) => {
	return (
		<div className="text-[11px]">
			<LetterHead />
			<div className="text-lg text-center mb-2">OUTPATIENT COMPLEX FORM</div>
			<Box sx={{ height: 3, backgroundColor: 'black', marginBottom: '2mm' }}></Box>

			<Grid>
				<TextField label="Patient's Name:" content={reportForm.PatientName} />
				<TextField label="Age/Sex:" content={reportForm.AgeSex} />
				<TextField label="Date:" content={dateFormat(reportForm.Date)} />
				<TextField label="Address:" content={reportForm.Address} />
				<TextField label="Contact Number:" content={reportForm.ContactNumber} className="col-span-2" />
				<TextField label="Date of Birth (mm/dd/yyyy):" content={dateFormat(reportForm.Birthdate)} />
				<TextField label="Civil Status:" content={reportForm.CivilStatus} />
				<TextField label="Religion:" content={reportForm.Religion} />
			</Grid>

			<Box sx={{ height: 3, backgroundColor: 'black', marginTop: '2mm', marginBottom: '4mm' }}></Box>

			<TextField label="Chief Complaint/s:" content={reportForm.ChiefComplaint} underline />

			<div className="mt-[2mm] mb-[2mm] flex justify-between">
				<div>Vital Signs:</div>

				<div className="flex gap-1">
					<div>BP:</div>
					<Text width="12mm" underline>
						{reportForm.BP}
					</Text>
					<div>mmHg</div>
				</div>
				<div className="flex gap-1">
					<div>HR:</div>
					<Text width="12mm" underline>
						{reportForm.HR}
					</Text>
					<div>bpm</div>
				</div>
				<div className="flex gap-1">
					<div>RR:</div>
					<Text width="12mm" underline>
						{reportForm.RR}
					</Text>
					<div>cpm</div>
				</div>
				<div className="flex gap-1">
					<div>T:</div>
					<Text width="12mm" underline>
						{reportForm.Temp}
					</Text>
					<div>&#8451;</div>
				</div>
				<div className="flex gap-1">
					<div>Weight:</div>
					<Text width="12mm" underline>
						{reportForm.Weight}
					</Text>
					<div>kg</div>
				</div>
				<div className="flex gap-1">
					<Label>O2 Sat:</Label>
					<Text width="12mm" underline>
						{reportForm.O2Sat}
					</Text>
					<div>%</div>
				</div>
			</div>

			<Table>
				<tbody>
					<tr>
						<TableCell>
							<div>Covid-19 Immunization Status:</div>
							<Text>{reportForm.Covid19ImmunizationStatus}</Text>

							<div className="mt-2">History of Present Illness:</div>
							<Text>{reportForm.HistoryOfPresentIllness}</Text>

							<div className="mt-2">Past Medical History:</div>
							<Text>{reportForm.PastMedicalHistory}</Text>

							<div className="mt-2">Family History:</div>
							<Text>{reportForm.FamilyHistory}</Text>

							<div className="mt-2">Personal and Social History:</div>
							<Text>{reportForm.PersonalAndSocialHistory}</Text>
						</TableCell>
						<TableCell sx={{ verticalAlign: 'top' }}>
							<div>Physical Examination:</div>
							<Text>{reportForm.PhysicalExamination}</Text>
						</TableCell>
					</tr>
					<tr>
						<TableCell>
							<div>Diagnosis:</div>
							<Text>{reportForm.Diagnosis}</Text>
						</TableCell>
						<TableCell>
							<div>ICD10 Code:</div>
							<Text>{reportForm.ICD10Code}</Text>
						</TableCell>
					</tr>
					<tr>
						<TableCell colSpan={2}>
							<div>Doctor's Order:</div>
							<Text>{reportForm.DoctorsOrder}</Text>
						</TableCell>
					</tr>
					<tr>
						<TableCell colSpan={2}>
							<div>
								<div>Nurse's Notes:</div>
								<Text>{reportForm.NurseNotes}</Text>
							</div>

							<TextField
								label="Name & Signature:"
								content={reportForm.NameAndSignature}
								className="justify-end mt-[2mm]"
								width="40mm"
								underline
							/>
						</TableCell>
					</tr>
					<tr>
						<TableCell>
							<div>Consent:</div>
							<Text underline textAlign="center">
								{reportForm.Consent}
							</Text>
							<div className="text-center">Patient’s/Guardian’s Signature Over Printed Name/ Date</div>
						</TableCell>
						<TableCell>
							<div>Attending Physician:</div>
							<div className="flex">
								<Text width="auto">{reportForm.AttendingPhysician}</Text>
								<Label>, M.D.</Label>
							</div>
						</TableCell>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

export default OutPatientComplexFormPrint;
