import { HButtonProps } from '@hybrain/mui.ui.inputs.h-button';
import BaseButton from './BaseButton';
import PrintIcon from '@mui/icons-material/Print';

const PrintButton = ({ children, ...props }: HButtonProps) => {
	return (
		<BaseButton {...props} startIcon={<PrintIcon />}>
			{children ?? 'Print'}
		</BaseButton>
	);
};

export default PrintButton;
