import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import assessmentRoutes from './assessment-routes';
import bloodTransfusionSheetRoutes from './blood-transfusion-sheet-routes';
import consentRoutes from './consents-routes';
import doctorsNotesRoutes from './doctors-notes-routes';
import ivFluidSheetRoutes from './iv-fuild-sheet-routes';
import oxygenIntakeRoutes from './oxygen-intake-routes';
import medicationSheetRoutes from './medication-sheet-routes';
import reportsRoutes from './reports-routes';
import dischargeInstructionRoutes from './discharge-transactions-routes';

const ViewPatientPage = lazy(() => import('../pages/ViewPatient/ViewPatientPage'));
const KardexPage = lazy(() => import('../pages/ViewPatient/pages/Kardex/KardexPage'));
const NursesNotesPage = lazy(() => import('../pages/ViewPatient/pages/NursesNotes/NursesNotesPage'));
const LaboratoryDiagnosticsImagingPage = lazy(
	() => import('../pages/ViewPatient/pages/LaboratoryDiagnosticsImaging/LaboratoryDiagnosticsImagingPage')
);
const VaccinationRecordsPage = lazy(() => import('../pages/ViewPatient/pages/VaccinationRecords/VaccinationRecordsPage'));
const PatientAccountPage = lazy(() => import('../pages/ViewPatient/pages/PatientAccount/PatientAccountPage'));

const VitalSignsPage = lazy(() => import('../pages/ViewPatient/pages/VitalSigns/VitalSignsPage'));
const IntakeOutputRecordingDailyRecordPage = lazy(
	() =>
		import(
			'../pages/ViewPatient/pages/VitalSigns/components/IntakeOutputRecording/pages/IntakeOutputRecordingDailyRecord/IntakeOutputRecordingDailyRecordPage'
		)
);
const IntakeOutputRecordingTransactionsPage = lazy(
	() =>
		import(
			'../pages/ViewPatient/pages/VitalSigns/components/IntakeOutputRecording/pages/IntakeOutputRecordingTransactions/IntakeOutputRecordingTransactionsPage'
		)
);

const TreatmentRecordsPage = lazy(() => import('../pages/ViewPatient/pages/TreatmentRecords/TreatmentRecordsPage'));

const PHICClaimsPage = lazy(() => import('../pages/ViewPatient/pages/PHICClaims/PHICClaimsPage'));
const RequisitionPage = lazy(() => import('../pages/ViewPatient/pages/Requisition/RequisitionPage'));

const PatientTransactionsMenuPage = lazy(
	() => import('../pages/ViewPatient/pages/PatientTransactionsMenu/PatientTransactionsMenuPage')
);
const GraphicalChartPage = lazy(() => import('../pages/ViewPatient/pages/GraphicalChart/GraphicalChartPage'));
const EPrescriptionsPage = lazy(() => import('../pages/ViewPatient/pages/EPrescriptions/EPrescriptionsPage'));
const WatchOutForPage = lazy(() => import('../pages/ViewPatient/pages/WatchOutFor/WatchOutForPage'));
const OtherDocumentsPage = lazy(() => import('../pages/ViewPatient/pages/OtherDocuments/OtherDocumentsPage'));

const vewPatientRoutes: RouteObject = {
	path: 'view',
	element: <ViewPatientPage />,
	children: [
		{ index: true, element: <PatientTransactionsMenuPage /> },
		{ path: 'kardex', element: <KardexPage /> },
		{
			path: 'vital-signs',
			element: <VitalSignsPage />,
			children: [
				{ index: true, element: <IntakeOutputRecordingTransactionsPage /> },
				{ path: 'daily-record', element: <IntakeOutputRecordingDailyRecordPage /> },
			],
		},
		assessmentRoutes,
		ivFluidSheetRoutes,
		oxygenIntakeRoutes,
		bloodTransfusionSheetRoutes,
		medicationSheetRoutes,
		dischargeInstructionRoutes,
		{ path: 'laboratory-diagnostics-imaging', element: <LaboratoryDiagnosticsImagingPage /> },
		doctorsNotesRoutes,
		{ path: 'nurses-notes', element: <NursesNotesPage /> },
		{ path: 'treatment-records', element: <TreatmentRecordsPage /> },
		{ path: 'vaccination-records', element: <VaccinationRecordsPage /> },
		{ path: 'patient-account', element: <PatientAccountPage /> },
		consentRoutes,
		{ path: 'phic-claims', element: <PHICClaimsPage /> },
		reportsRoutes,
		{ path: 'requisition', element: <RequisitionPage /> },
		{ path: 'graphical-chart', element: <GraphicalChartPage /> },
		{ path: 'e-prescriptions', element: <EPrescriptionsPage /> },
		{ path: 'watch-out-for', element: <WatchOutForPage /> },
		{ path: 'other-documents', element: <OtherDocumentsPage /> },
	],
};

export default vewPatientRoutes;
