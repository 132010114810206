import React from 'react';

import { HFormSelect, HFormSelectProps } from '@hybrain/mui.ui.final-form.h-form-select';

export type FormSelectProps<T> = {} & HFormSelectProps<T>;

const FormSelect = <T extends any>(props: HFormSelectProps<T>) => {
	return <HFormSelect size="small" {...props} />;
};

export default FormSelect;
