import React from 'react';
import { observer } from 'mobx-react-lite';

import { styled } from '@mui/material/styles';

import { getAge } from 'helpers/utils/date';
import { getFullName } from 'helpers/utils/string';
import PatientStore from 'modules/private/stores/PatientStore';

import LetterHead from 'components/print/LetterHead';

interface ConsentFormLayoutProps {
	title: string | JSX.Element;
	children?: React.ReactNode;
}

const Information = styled('div')({
	display: 'grid',
	gridTemplateColumns: '140px 1fr 170px 150px',
	marginBottom: 40,
});

const ConsentFormLayout = ({ title, children }: ConsentFormLayoutProps) => {
	return (
		<div>
			<div className="hidden print:block">
				<LetterHead />
			</div>

			<div className="font-bold text-center text-lg mb-5 uppercase">{title}</div>
			<Information>
				<div>Name of Patient</div>
				<div className="font-bold">{getFullName(PatientStore.patient)}</div>

				<div>Case Number</div>
				<div className="font-bold">{PatientStore.patient.CaseID}</div>

				<div>Age / Sex</div>
				<div className="font-bold">
					{getAge(PatientStore.patient.Birthdate)} / {PatientStore.patient.Gender}
				</div>

				<div>Unit / Room / Bed No.</div>
				<div className="font-bold">{PatientStore.patient.Room}</div>
			</Information>

			{children}
		</div>
	);
};

export default observer(ConsentFormLayout);
