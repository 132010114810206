import React from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BaseButton, { BaseButtonProps } from './BaseButton';

const ViewButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton startIcon={<ContentPasteSearchIcon />} {...props}>
			{children ?? 'View'}
		</BaseButton>
	);
};

export default ViewButton;
