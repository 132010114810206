import React, { Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { HToast } from '@hybrain/mui.ui.feedback.h-toast';
import { HLoadingIndicator } from '@hybrain/mui.ui.feedback.h-loading-indicator';
import { HResponseDialog } from '@hybrain/mui.ui.dialogs.h-response-dialog';

import publicRoutes from '../modules/public/routes/public-routes';
import privateRoutes from '../modules/private/routes/private-routes';
import { DialogStore } from '@hybrain/mui.hooks.use-api';

const ResponseDialogComponent = observer(() => (
	<>
		<HToast
			open={DialogStore.response.responseType === 'toast' ? DialogStore.response.status : false}
			onClose={() => DialogStore.close()}
			type={DialogStore.response.type}
			message={DialogStore.response.content}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		/>
		<HResponseDialog
			open={DialogStore.response.responseType === 'dialog' ? DialogStore.response.status : false}
			onClose={() => DialogStore.close()}
			type={DialogStore.response.type}
			message={DialogStore.response.content}
		/>
	</>
));

const RoutesList = () => {
	const routes = useRoutes([publicRoutes, privateRoutes]);

	return routes;
};

const RoutesIndex = () => {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div className="flex flex-col min-h-screen">
						<HLoadingIndicator />
					</div>
				}
			>
				<ResponseDialogComponent />
				<RoutesList />
			</Suspense>
		</BrowserRouter>
	);
};

export default RoutesIndex;
