import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const DoctorsNotesPage = lazy(() => import('../pages/ViewPatient/pages/DoctorsNotes/DoctorsNotesPage'));
const CourseInTheWardPage = lazy(
	() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/CourseInTheWard/CourseInTheWardPage')
);
const DoctorsOrdersPage = lazy(() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/DoctorsOrders/DoctorsOrdersPage'));
const ProblemsListPage = lazy(() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/ProblemList/ProblemListPage'));
const ProvisionalDiagnosisPage = lazy(
	() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/ProvisionalDiagnosis/ProvisionalDiagnosisPage')
);
const FinalDiagnosisPage = lazy(() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/FinalDiagnosis/FinalDiagnosisPage'));
const AdmittingDiagnosisPage = lazy(
	() => import('../pages/ViewPatient/pages/DoctorsNotes/pages/AdmittingDiagnosis/AdmittingDiagnosisPage')
);

const doctorsNotesRoutes: RouteObject = {
	path: 'doctors-notes',
	element: <DoctorsNotesPage />,
	children: [
		{ index: true, element: <DoctorsOrdersPage /> },
		{ path: 'course-in-the-ward', element: <CourseInTheWardPage /> },
		{ path: 'problem-list', element: <ProblemsListPage /> },
		{ path: 'provisional-diagnosis', element: <ProvisionalDiagnosisPage /> },
		{ path: 'final-diagnosis', element: <FinalDiagnosisPage /> },
		{ path: 'admitting-diagnosis', element: <AdmittingDiagnosisPage /> },
	],
};

export default doctorsNotesRoutes;
