import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useApi from 'hooks/useApi';
import Corporation from 'interfaces/corporation.model';
import { getCorporation } from 'services/maintenance/maintenance.service';

const HospitalLogo = require(`assets/images/${process.env.REACT_APP_LOGO}`);

const Root = styled('div')({
	position: 'relative',
	display: 'flex',
	marginBottom: '2mm',
	minHeight: '21mm',
	justifyContent: 'center',
	alignItems: 'center',
});

const Logo = styled('img')({
	position: 'absolute',
	left: '1mm',
	top: '50%',
	transform: 'translateY(-50%)',
	width: '18mm',
});

const LetterHead = () => {
	const { data } = useApi<Corporation[]>({
		action: getCorporation,
		immediate: true,
	});

	const corporation = useMemo(() => {
		return data?.[0] ?? {};
	}, [data]);

	return (
		<Root>
			<Logo src={HospitalLogo} />
			<div className="h-full">
				<Typography className="text-center max-w-full mx-auto" fontSize={12} fontWeight={700}>
					{corporation.Name}
				</Typography>
				<div className="mt-[0.5mm] mx-auto text-center max-w-full leading-[1.2]">
					<div>{corporation.Address1Format}</div>
					<div>{corporation.Address2Format}</div>
					{corporation.HospitalContactNo && <div>Contact #: {corporation.HospitalContactNo}</div>}
				</div>
			</div>
		</Root>
	);
};

export default LetterHead;
