import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const BloodTransfusionSheetPage = lazy(() => import('../pages/ViewPatient/pages/BloodTransfusionSheet/BloodTransfusionSheetPage'));
const BloodTransfusionLogsPage = lazy(
	() => import('../pages/ViewPatient/pages/BloodTransfusionSheet/pages/BloodTransfusionLogs/BloodTransfusionLogsPage')
);
const BloodTransfusionMonitoringPage = lazy(
	() => import('../pages/ViewPatient/pages/BloodTransfusionSheet/pages/BloodTransfusionMonitoring/BloodTransfusionMonitoringPage')
);
const bloodTransfusionSheetRoutes: RouteObject = {
	path: 'blood-transfusion-sheet',
	element: <BloodTransfusionSheetPage />,
	children: [
		{ index: true, element: <BloodTransfusionMonitoringPage /> },
		{ path: 'logs', element: <BloodTransfusionLogsPage /> },
	],
};

export default bloodTransfusionSheetRoutes;
