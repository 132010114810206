import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import BaseDialog from 'components/feedback/BaseDialog';
import BaseForm from 'components/form/BaseForm';
import ProceedButton from 'components/inputs/buttons/ProceedButton';
import GridFormTextField from 'components/form/grid/GridFormTextField';
import FormSignaturePad from 'components/form/FormSignaturePad';
import GridFormDateTimePicker from 'components/form/grid/GridFormDateTimePicker';
import PatientStore from 'modules/private/stores/PatientStore';
import { dateTimeFormat } from 'helpers/utils/date';
import { getFullName } from 'helpers/utils/string';

import FormTemplateLayout from '../components/FormTemplateLayout';
import TextPlaceholder from '../components/TextPlaceholder';
import SignatureLayout from '../components/SignatureLayout';
import ConsentFormLayout from '../components/ConsentFormLayout';
import useConsentForm from '../hooks/useConsentForm';
import WitnessAuthorizedPersonnelComponent, {
	WitnessAuthorizedPersonnelFormProps,
} from '../components/WitnessAuthorizedPersonnelComponent';
import CadaverFormPrint from '../components/print-layouts/CadaverFormPrint';
import useBreadcrumbViewPatient from 'hooks/useBreadcrumbViewPatient';

export type CadaverForm = {
	NurseOnDuty: string;
	PreparedDate: string;
	NurseSignature: string;
	SecurityPersonnel: string;
	ReleasedDateTime: string;
	SecurityPersonnelSignature: string;
} & WitnessAuthorizedPersonnelFormProps;

interface CadaverFormPageProps {}

const CadaverFormPage: React.FC<CadaverFormPageProps> = () => {
	const [openDialog, setOpenDialog] = useState(false);
	const [form, setForm] = useState<CadaverForm>({} as CadaverForm);
	const componentToPrintRef = useRef<any>(null);

	useBreadcrumbViewPatient([{ label: 'Consents', path: '/patients/view/consents' }, { label: 'Cadaver Form' }]);

	const { handleSubmit, handlePrint, loading, loadingUpdate } = useConsentForm({
		setForm,
		setOpenDialog,
		printComponent: componentToPrintRef.current,
	});

	return (
		<FormTemplateLayout label="CADAVER FORM" handlePopulateData={() => setOpenDialog(true)} handlePrint={handlePrint}>
			<div className="hidden">
				<div ref={componentToPrintRef}>
					<CadaverFormPrint form={form} />
				</div>
			</div>

			<BaseDialog open={openDialog} onClose={() => setOpenDialog(false)} title="Release of Cadaver Form" contentClassName="p-3">
				<BaseForm
					initialValues={{
						PreparedDate: new Date(),
						ReleasedDateTime: new Date(),
						...(form as {}),
					}}
					onSubmit={handleSubmit}
					buttonComponent={<ProceedButton size="large" loading={loading || loadingUpdate} />}
				>
					<WitnessAuthorizedPersonnelComponent title="Authorized Representative" />

					<Paper className="p-3 mt-3" elevation={4}>
						<div className="text-lg font-bold mb-2">Prepared by</div>
						<Grid container spacing={1}>
							<GridFormTextField name="NurseOnDuty" label="Nurse on Duty" />
							<GridFormDateTimePicker name="PreparedDate" label="Prepared Date" md={6} />

							<Grid item xs={12}>
								<FormSignaturePad name="NurseSignature" />
							</Grid>
						</Grid>
					</Paper>

					<Paper className="p-3 mt-3" elevation={4}>
						<div className="text-lg font-bold mb-2">Released by</div>
						<Grid container spacing={1}>
							<GridFormTextField name="SecurityPersonnel" label="Security Personnel" />
							<GridFormDateTimePicker name="ReleasedDateTime" label="Released Date" md={6} />

							<Grid item xs={12}>
								<FormSignaturePad name="SecurityPersonnelSignature" />
							</Grid>
						</Grid>
					</Paper>
				</BaseForm>
			</BaseDialog>

			<ConsentFormLayout title="RELEASE OF CADAVER FORM">
				<div>
					I, <TextPlaceholder text={form.ARName} /> of legal age and a resident of <TextPlaceholder text={form.ARAddress} />{' '}
					hereby received the dead body of my <TextPlaceholder text={form.ARRelationship} /> by the name of{' '}
					<TextPlaceholder text={getFullName(PatientStore.patient)} /> who died in this hospital on{' '}
					<TextPlaceholder text={dateTimeFormat(form.ReleasedDateTime)} />.
				</div>

				<SignatureLayout
					className="mt-10"
					alignItems="flex-end"
					items={[
						{
							label: 'Printed Name and Signature of Claimant',
							signature: form.ARSignature,
							value: form.ARName,
						},
						{ label: 'Relationship to Deceased Patient', value: form.ARRelationship },
					]}
				/>

				<div className="mt-6">Prepared by:</div>

				<SignatureLayout
					className="mt-5"
					alignItems="flex-end"
					items={[
						{ label: 'Name of Nurse on Duty', value: form.NurseOnDuty, signature: form.NurseSignature },
						{ label: 'Date and Time', value: dateTimeFormat(form.PreparedDate) },
					]}
				/>

				<div className="mt-10">Released by:</div>

				<SignatureLayout
					className="mt-5"
					alignItems="flex-end"
					items={[
						{
							label: 'Printed Name and Signature of Security Personnel',
							value: form.SecurityPersonnel,
							signature: form.SecurityPersonnelSignature,
							minWidth: 400,
						},
						{ label: 'Date and Time', value: dateTimeFormat(form.ReleasedDateTime) },
					]}
				/>
			</ConsentFormLayout>
		</FormTemplateLayout>
	);
};

export default observer(CadaverFormPage);
