import Grid from '@mui/material/Grid';

import BaseForm from 'components/form/BaseForm';
import ProceedButton from 'components/inputs/buttons/ProceedButton';
import GridFormTextField from 'components/form/grid/GridFormTextField';
import api from 'api';

import BaseDialog from './BaseDialog';
import { SecondaryLoginDialogStore } from '@hybrain/mui.hooks.use-api';
import AuthStore from 'modules/private/stores/AuthStore';

interface SecondaryLoginDialogProps {
	open: boolean;
	onClose: () => void;
	onLoginSuccess?: (userData: any) => void;
	loading?: boolean;
}

const SecondaryLoginDialog = ({ onLoginSuccess, loading, ...props }: SecondaryLoginDialogProps) => {
	const handleSubmit = async (values: any) => {
		try {
			SecondaryLoginDialogStore.setLoading(true);

			const { data } = await api().post<any>('/users/transactional-login', values);
			if (!!data.user) {
				onLoginSuccess?.(data);
				props.onClose();
			}
		} finally {
			SecondaryLoginDialogStore.setLoading(false);
		}
	};

	return (
		<BaseDialog title="Please re-login" maxWidth="xs" {...props}>
			<BaseForm
				initialValues={{
					Username: AuthStore.user?.UserAccess,
					Password: '',
				}}
				onSubmit={handleSubmit}
				buttonComponent={<ProceedButton loading={loading}>Login</ProceedButton>}
				noSubmitButton={false}
			>
				<Grid container spacing={1}>
					<GridFormTextField name="Username" label="Username" className="mt-3" required />
					<GridFormTextField name="Password" label="Password" type={'password'} required />
				</Grid>

				{/* <div className="mt-2 text-center">
					<BaseButton onClick={() => onLoginSuccess({})}>Fake Proceed</BaseButton>
				</div> */}
			</BaseForm>
		</BaseDialog>
	);
};

export default SecondaryLoginDialog;
