import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const DischargeTransactionsPage = lazy(
	() => import('../pages/ViewPatient/pages/DischargeTransactions/DischargeTransactionsPage')
);
const DischargeTransactionFormPage = lazy(
	() => import('../pages/ViewPatient/pages/DischargeTransactions/pages/DischargeTransactionFormPage')
);

const dischargeInstructionRoutes: RouteObject = {
	path: 'discharge-instructions',
	children: [
		{ index: true, element: <DischargeTransactionsPage /> },
		{ path: 'add', element: <DischargeTransactionFormPage formType="Add" /> },
		{ path: 'update', element: <DischargeTransactionFormPage formType="Update" /> },
	],
};

export default dischargeInstructionRoutes;
