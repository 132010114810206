import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
	path?: string;
} & BaseButtonProps;

const BackButton = ({ children, path, ...props }: BackButtonProps) => {
	const navigate = useNavigate();

	return (
		<BaseButton startIcon={<ArrowBackIcon />} backgroundColor="default" onClick={() => (path ? navigate(path) : navigate(-1))} {...props}>
			{children ?? 'Back'}
		</BaseButton>
	);
};

export default BackButton;
