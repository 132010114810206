import React from 'react';
import { HForm, HFormProps } from '@hybrain/mui.ui.final-form.h-form';
import SaveIcon from '@mui/icons-material/Save';

const BaseForm = ({ submitButtonProps, submitButtonText, ...props }: HFormProps) => (
	<HForm
		submitButtonProps={
			submitButtonProps
				? submitButtonProps
				: {
						minWidth: '140px',
						startIcon: <SaveIcon />,
						sx: {
							lineHeight: '120%',
						},
				  }
		}
		keepDirtyOnReinitialize
		autoParseDate
		submitButtonText={submitButtonText ?? 'SAVE'}
		// submitButtonText={
		// 	<div className="flex items-center gap-x-1">
		// 		<SaveIcon />
		// 		<div className="flex-shrink-0">SAVE</div>
		// 	</div>
		// }
		{...props}
	/>
);

export default BaseForm;
