import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

export interface TextPlaceholderProps {
	className?: string;
	text?: string | JSX.Element | number | null;
	minWidth?: string | number;
	label?: string;
	fullWidth?: boolean;
	labelClassName?: string;
	printMinWidth?: string | number;
}

const PlaceHolder = styled('div', {
	shouldForwardProp: (prop: string) => !['minWidth', 'printMinWidth'].includes(prop),
})<Partial<TextPlaceholderProps>>(({ minWidth, printMinWidth }) => ({
	borderBottom: '1px solid black',
	minWidth: minWidth ?? 300,
	padding: '0 10px',

	'@media print': {
		minWidth: printMinWidth,
	},
}));

const getClass = (text?: string | JSX.Element | number | null, label?: string) => {
	if (!!label) {
		if (!text) {
			return 'translate-y-4 mb-4';
		} else {
			return 'align-top';
		}
	}
};

const TextPlaceholder: React.FC<TextPlaceholderProps> = ({
	className,
	text,
	minWidth,
	label,
	fullWidth,
	labelClassName,
	printMinWidth,
}) => {
	return (
		<div className={clsx(fullWidth ? 'block' : 'inline-block', getClass(text, label))}>
			<PlaceHolder className={className} minWidth={minWidth} printMinWidth={printMinWidth}>
				{text}
			</PlaceHolder>
			{label && <div className={clsx('text-center', labelClassName)}>{label}</div>}
		</div>
	);
};

export default TextPlaceholder;
