import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import CadaverFormPage from '../pages/ViewPatient/pages/Consents/pages/CadaverFormPage';

const ConsentsDashboardPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/ConsentsDashboardPage'));
// const CadaverFormPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/CadaverFormPage'));
const RefusalOfTreatmentProcedure = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/RefusalOfTreatmentProcedure'));
const HAMAWaiverPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/HAMAWaiverPage'));
const FallPreventionAgreementFormPage = lazy(
	() => import('../pages/ViewPatient/pages/Consents/pages/FallPreventionAgreementFormPage')
);
const DoNotResuscitateFormPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/DoNotResuscitateFormPage'));
const ConsentForAnesthesiaPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/ConsentForAnesthesiaPage'));
const ConsentForTreatmentProceduresPage = lazy(
	() => import('../pages/ViewPatient/pages/Consents/pages/ConsentForTreatmentProceduresPage')
);
const ConsentForOperationProcedurePage = lazy(
	() => import('../pages/ViewPatient/pages/Consents/pages/ConsentForOperationProcedurePage')
);
const ConsentForBloodTransfusionPage = lazy(
	() => import('../pages/ViewPatient/pages/Consents/pages/ConsentForBloodTransfusionPage')
);
const ConsentForCovid19TestingPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/ConsentForCovid19TestingPage'));
const AdmissionAndTreatmentPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/AdmissionAndTreatmentPage'));
const ReferralFormPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/ReferralFormPage'));
const DataPrivacyConsentFormPage = lazy(() => import('../pages/ViewPatient/pages/Consents/pages/DataPrivacyConsentForm'));

const consentRoutes: RouteObject = {
	path: 'consents',
	children: [
		{ index: true, element: <ConsentsDashboardPage /> },
		{ path: 'cadaver-form', element: <CadaverFormPage /> },
		{ path: 'refusal-of-treatment-procedure', element: <RefusalOfTreatmentProcedure /> },
		{ path: 'hama-waiver', element: <HAMAWaiverPage /> },
		{ path: 'fall-prevention-agreement', element: <FallPreventionAgreementFormPage /> },
		{ path: 'do-not-resuscitate-form', element: <DoNotResuscitateFormPage /> },
		{ path: 'consent-for-anesthesia', element: <ConsentForAnesthesiaPage /> },
		{ path: 'consent-for-treatment-procedures', element: <ConsentForTreatmentProceduresPage /> },
		{ path: 'consent-for-operation-procedure', element: <ConsentForOperationProcedurePage /> },
		{ path: 'consent-for-blood-transfusion', element: <ConsentForBloodTransfusionPage /> },
		{ path: 'consent-for-covid19-testing', element: <ConsentForCovid19TestingPage /> },
		{ path: 'admission-and-treatment', element: <AdmissionAndTreatmentPage /> },
		{ path: 'referral-form', element: <ReferralFormPage /> },
		{ path: 'data-privacy-consent-form', element: <DataPrivacyConsentFormPage /> },
	],
};

export default consentRoutes;
