import { useEffect } from 'react';
import { BreadcrumbProps } from 'components/navigation/Breadcrumbs';
import BreadcrumbsStore from 'stores/BreadcrumbsStore';

const useBreadcrumb = (props: BreadcrumbProps[]) => {
	useEffect(() => {
		BreadcrumbsStore.update(props);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useBreadcrumb;
