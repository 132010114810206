import React from 'react';
import { HTable, HTableProps } from '@hybrain/mui.ui.data-display.h-table';
import { styled, darken } from '@mui/material/styles';

const StyledTable = styled(HTable, {
	shouldForwardProp: (prop: string) => !['tableColumnAlign', 'tableColumnPadding'].includes(prop),
})(({ theme }) => ({
	width: 'auto%',

	'& thead th': {
		position: 'sticky',
		top: 0,
		paddingTop: '9px',
		paddingBottom: '9px',
		fontWeight: 400,
	},

	'& tbody tr td': {
		padding: ({ tableColumnPadding }: { tableColumnPadding: string | number }) => tableColumnPadding,
		verticalAlign: ({ tableRowAlignment }: { tableRowAlignment: string | number }) => tableRowAlignment,
	},

	'& .MuiPagination-ul': {
		alignItems: 'stretch',

		'& button': {
			borderRadius: 0,
		},

		'& li': {
			border: `1px solid ${theme.palette.primary.main}`,

			'&:not(:last-child)': {
				borderRight: 'none',
			},

			'&:first-of-type': {
				borderRadius: '6px 0 0 6px',
			},
			'&:last-child': {
				borderRadius: '0 6px 6px 0',
			},

			'& button': {
				margin: 0,

				'&:not(.Mui-selected)': {
					backgroundColor: 'transparent',
				},

				'&.Mui-selected': {
					color: '#fff',
				},
			},

			'& .MuiPaginationItem-ellipsis': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			},
		},
	},
})) as typeof HTable;

type BaseTableProps<T> = {
	tableRowAlignment?:
		| 'baseline'
		| 'length'
		| 'sub'
		| 'super'
		| 'top'
		| 'text-top'
		| 'middle'
		| 'bottom'
		| 'text-bottom'
		| 'initial'
		| 'inherit';
	tableColumnPadding?: string | number;
} & HTableProps<T>;

function BaseTable<T = any>(props: BaseTableProps<T>) {
	return (
		<StyledTable
			headerAlign="center"
			tableHeaderBackgroundColor={darken('#c1c1c3', 0.07)}
			withHeaderBorder
			dateTimeFormat="timezone"
			sortable={true}
			actionColumnOptions={{
				position: 'left',
				fit: true,
			}}
			{...props}
		/>
	);
}

export default BaseTable;
