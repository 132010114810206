import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const AssessmentsPage = lazy(() => import('../pages/ViewPatient/pages/Assessments/AssessmentsPage'));
const AdmittingAssessmentPage = lazy(
	() => import('../pages/ViewPatient/pages/Assessments/pages/AdmittingAssessment/AdmittingAssessmentPage')
);
const ShiftingAssessmentPage = lazy(() => import('../pages/ViewPatient/pages/Assessments/pages/ShiftingAssessment/ShiftingAssessmentPage'));
const SupplementalAssessmentPage = lazy(
	() => import('../pages/ViewPatient/pages/Assessments/pages/SupplementalAssessment/SupplementalAssessmentPage')
);

const assessmentRoutes: RouteObject = {
	path: 'assessments',
	children: [
		{ index: true, element: <AssessmentsPage /> },
		{ path: 'admitting', element: <AdmittingAssessmentPage /> },
		{ path: 'shifting', element: <ShiftingAssessmentPage /> },
		{ path: 'shifting/view', element: <ShiftingAssessmentPage /> },
		{ path: 'supplemental', element: <SupplementalAssessmentPage /> },
		{ path: 'supplemental/view', element: <SupplementalAssessmentPage /> },
	],
};

export default assessmentRoutes;
