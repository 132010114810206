import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import { HTextFieldProps } from '@hybrain/mui.ui.inputs.h-text-field';

import BaseTextField from '../BaseTextField';

interface GridTextFieldProps {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
}

const GridTextField: React.FC<GridTextFieldProps & HTextFieldProps> = ({ className, gridProps, xl, lg, md, xs, ...props }) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} className={className} {...gridProps}>
			<BaseTextField {...props} />
		</Grid>
	);
};

export default GridTextField;
