import { IReactToPrintProps, useReactToPrint } from 'react-to-print';

type UsePrintProps = {
	fontSize?: number;
} & IReactToPrintProps;

const usePrint = ({ fontSize, ...props }: UsePrintProps) =>
	useReactToPrint({
		// pageStyle: '@media print { body { -webkit-print-color-adjust: exact; }}',
		pageStyle: `@media print { body { -webkit-print-color-adjust: exact;  } * { font-size: ${
			fontSize ?? 11
		}px; } @page { margin: 17mm; margin-top: 15mm; }}`,
		...props,
	});

export default usePrint;
