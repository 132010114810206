import React from 'react';
import { HButton, HButtonProps } from '@hybrain/mui.ui.inputs.h-button';

export type BaseButtonProps = {} & HButtonProps;

const BaseButton: React.FC<BaseButtonProps> = (props) => {
	return <HButton minHeight="36px" {...props} />;
};

export default BaseButton;
