import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import FormTextField from '../FormTextField';
import { HFormTextFieldProps } from '@hybrain/mui.ui.final-form.h-form-text-field';

interface GridFormTextFieldProps {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	sm?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
}

const GridFormTextField: React.FC<HFormTextFieldProps & GridFormTextFieldProps> = ({
	className,
	gridProps,
	xl,
	lg,
	md,
	xs,
	sm,
	...props
}) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} sm={sm} className={className} {...gridProps}>
			<FormTextField {...props} />
		</Grid>
	);
};

export default GridFormTextField;
