import DateFilterInputGroup from 'components/inputs/DateFilterInputGroup';
import useBreadcrumb from 'hooks/useBreadbrumb';
import React, { useEffect, useState, useMemo } from 'react';
import useApi from 'hooks/useApi';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import BaseTable from 'components/data-display/BaseTable';
import { joinStrings } from 'helpers/utils/string';
import { convertToSqlDate, dateTimeFormat } from 'helpers/utils/date';
import ViewButton from 'components/inputs/buttons/ViewButton';
import localforage from 'localforage';
import { observer } from 'mobx-react-lite';
import BaseTextField from 'components/inputs/BaseTextField';
import BaseButton from 'components/inputs/buttons/BaseButton';

export interface MedicalHistoryDashboardProps {}
export interface PatientMedicalHistoryDashboard {
	PatientID: string;
	HospitalID: string;
	FirstName: string;
	MiddleName: string;
	LastName: string;
	Suffix: string;
	Gender: string;
	AdmissionDate: string;
	AdmissionTime: string;
	RecordCount: number;
	Birthdate: string;
}

export const formatPatientName = (patient: PatientMedicalHistoryDashboard) => {
	return `${patient?.LastName ?? ''}, ${patient?.FirstName ?? ''} ${patient?.MiddleName ?? ''}`;
};

const MedicalHistoryDashboard: React.FC<MedicalHistoryDashboardProps> = () => {
	useBreadcrumb([{ label: 'Medical History Records' }]);
	const navigate = useNavigate();
	const [dateRange, setDateRange] = useState<{ dateFrom?: string; dateTo?: string }>({
		dateFrom: '',
		dateTo: '',
	});
	const [search, setSearch] = useState<string>('');
	const {
		data: patients,
		setData: setPatients,
		loading: dashboardLoading,
		callApi: loadDashboard,
	} = useApi<PatientMedicalHistoryDashboard[]>(
		{
			url: () => `patientregistry/medical-history-dashboard`,
			// action: getMedicalHistoryDashboard,
			onSuccess: (response: any) => {
				setPatients(response?.data?.response);
			},
			params: {
				dateFrom: convertToSqlDate(dateRange.dateFrom),
				dateTo: convertToSqlDate(dateRange.dateTo),
			},
			immediate: true,
		},
		[]
	);

	function mergeAdmissionDateAndTime(patient: PatientMedicalHistoryDashboard) {
		var string = dateTimeFormat(patient?.AdmissionDate);
		var getDate = string?.split(',');

		var time = dateTimeFormat(patient?.AdmissionTime);
		var getTime = time?.split(',');

		return joinStrings([getDate?.[0], getTime?.[1]]);
	}

	const handleClickViewBtn = async (patient: PatientMedicalHistoryDashboard) => {
		localforage.setItem('medicalHistoryPatient', patient);
		navigate(`/records/view`, { state: patient });
	};

	useEffect(() => {
		loadDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange.dateFrom, dateRange.dateTo]);

	const filteredPatients = useMemo(() => {
		if (!search) return patients;
		return patients.filter((patient) => {
			return (
				patient.FirstName?.toString().toLowerCase().includes(search?.toLowerCase()) ||
				patient.MiddleName?.toString().toLowerCase().includes(search?.toLowerCase()) ||
				patient.LastName?.toString().toLowerCase().includes(search?.toLowerCase())
			);
		});
	}, [patients, search]);

	return (
		<div>
			<div className="flex items-center gap-x-2">
				<DateFilterInputGroup
					onSubmit={(values) => {
						setDateRange({
							dateFrom: values.dateFrom,
							dateTo: values.dateTo,
						});
					}}
					autoSave
					autoSaveDebounce={0}
				/>

				<div className="flex flex-wrap gap-4 mt-4">
					<BaseButton
						onClick={() => {
							setDateRange({
								dateFrom: '',
								dateTo: '',
							});
							setSearch('');
						}}
					>
						Clear
					</BaseButton>
				</div>
			</div>

			<div className="mt-2 mb-1">Search Patient</div>
			<BaseTextField
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				borderRadius={100}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>

			<div className="mt-5">
				<BaseTable
					headers={[
						{
							label: 'Hospital No.',
							value: 'HospitalID',
							width: '50px',
						},
						{
							label: 'Records',
							value: 'RecordCount',
							width: '20px',
						},
						{
							label: 'Admission Date/Time',
							value: (patient: PatientMedicalHistoryDashboard) => mergeAdmissionDateAndTime(patient),
							width: '20px',
						},
						{
							label: 'Patient Name',
							value: (patient: PatientMedicalHistoryDashboard) => formatPatientName(patient),
						},
						{
							label: 'Gender',
							value: 'Gender',
						},
					]}
					items={filteredPatients}
					loading={dashboardLoading}
					rowsPerPage={50}
					actionColumn={(item) => <ViewButton onClick={() => handleClickViewBtn(item)} />}
				/>
			</div>
		</div>
	);
};

export default observer(MedicalHistoryDashboard);
