import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const MedicationSheetPage = lazy(() => import('../pages/ViewPatient/pages/MedicationSheet/MedicationSheetPage'));
const ListOfMedicationsPage = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/ListOfMedications/ListOfMedicationsPage')
);
const DrugAllergiesResistancePage = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/DrugAllergiesResistance/DrugAllergiesResistancePage')
);
const DrugAdministrationLogsPage = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/DrugAdministrationLogs/DrugAdministrationLogsPage')
);
const RenderedItemsAndServicesPage = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/RenderedItemsAndServices/RenderedItemsAndServicesPage')
);
const RoundTheClockMedicines = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/RoundTheClockMedicines/RoundTheClockMedicinesPage')
);
const DrugInteractionPage = lazy(
	() => import('../pages/ViewPatient/pages/MedicationSheet/pages/DrugInteraction/DrugInteractionPage')
);
const RequisitionPage = lazy(() => import('../pages/ViewPatient/pages/MedicationSheet/pages/Requisitions/RequisitionPage'));

const medicationSheetRoutes: RouteObject = {
	path: 'medication-sheet',
	element: <MedicationSheetPage />,
	children: [
		{ index: true, element: <ListOfMedicationsPage /> },
		{ path: 'schedule-drug-administration', element: <RoundTheClockMedicines /> },
		{ path: 'drug-interaction', element: <DrugInteractionPage /> },
		{ path: 'drug-administration-logs', element: <DrugAdministrationLogsPage /> },
		{ path: 'rendered-items-and-services', element: <RenderedItemsAndServicesPage /> },
		{ path: 'drug-allergies-resistance', element: <DrugAllergiesResistancePage /> },
		{ path: 'requisitions-list', element: <RequisitionPage /> },
		{ path: 'prn-drug-administration', element: <RoundTheClockMedicines category="PRN" /> },
	],
};

export default medicationSheetRoutes;
