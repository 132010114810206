import { useEffect } from 'react';
import { joinStrings } from 'helpers/utils/string';
import BreadcrumbsStore from 'stores/BreadcrumbsStore';
import { BreadcrumbProps } from 'components/navigation/Breadcrumbs';
import Patient from 'interfaces/patient.model';
import localforage from 'localforage';

const useBreadcrumbViewPatient = (props: BreadcrumbProps[]) => {
	useEffect(() => {
		(async () => {
			const patient: Patient = (await localforage.getItem('patient')) ?? ({} as Patient);

			if (Object.keys(patient).length === 0) {
				return;
			}

			BreadcrumbsStore.update([
				{ label: 'Patients', path: '/patients' },
				{ label: patient.PatientName, path: '/patients/view' },
				...props,
			]);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useBreadcrumbViewPatient;
