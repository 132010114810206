import React, { useState, useEffect } from 'react';
import useApi from 'hooks/useApi';
import BaseTable from 'components/data-display/BaseTable';
import { PatientMedicalHistoryDashboard, formatPatientName } from './MedicalHistoryDashboard';
import { getPatientMedicalHistory } from 'services/medical-history/medical-history.service';
import localforage from 'localforage';
import { observer } from 'mobx-react-lite';
import BreadcrumbsStore from 'stores/BreadcrumbsStore';
import { convertToSqlDate, dateTimeFormat, getAge } from 'helpers/utils/date';
import GridTextField from 'components/inputs/grid/GridTextField';
import { Grid, Skeleton } from '@mui/material';
import styled from '@emotion/styled';

interface ViewPatientMedicalRecordProps {}

interface ViewMedicalHistory {
	AdmissionDate: string;
	DischargeDiagnosis: string;
	Discharge_FollowupCheckupDate: string;
	PatientRegID: string;
	AdmissionTime: string;
	AttendingPhysician: string;
	CaseID: string;
	SubServiceType: string;
}

const ViewPatientMedicalRecord: React.FC<ViewPatientMedicalRecordProps> = () => {
	const [patient, setPatient] = useState<PatientMedicalHistoryDashboard>({} as PatientMedicalHistoryDashboard);
	const [loadingPage, setLoadingPage] = useState<boolean>(true);
	useEffect(() => {
		(async () => {
			const patient: PatientMedicalHistoryDashboard =
				(await localforage.getItem('medicalHistoryPatient')) ?? ({} as PatientMedicalHistoryDashboard);

			if (Object.keys(patient).length === 0) {
				return;
			}

			BreadcrumbsStore.update([
				{ label: 'Medical History Records', path: '/records' },
				{ label: formatPatientName(patient), path: '/patients/view' },
			]);

			setPatient(patient);
			viewPatientMedicalRecords();
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		data: medicalRecords,
		loading,
		callApi: viewPatientMedicalRecords,
	} = useApi<any>({
		action: getPatientMedicalHistory,
		onBeforeApiCall: async () => {
			const patient: PatientMedicalHistoryDashboard =
				(await localforage.getItem('medicalHistoryPatient')) ?? ({} as PatientMedicalHistoryDashboard);
			return patient.PatientID;
		},
		onSuccess: () => setLoadingPage(false),
	});

	const getPatientProfile = (patient: PatientMedicalHistoryDashboard) => {
		var bday = convertToSqlDate(patient?.Birthdate ?? '');
		var age = getAge(patient?.Birthdate) ?? '';
		var gender = patient?.Gender ?? '';
		return [bday, age, gender].join(' / ');
	};

	const Typography = styled('div')({
		fontSize: '20px',
		fontWeight: 'bold',
	});

	return (
		<div>
			{loadingPage ? (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Skeleton variant="rectangular" width={'30%'} height={30} />
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Skeleton variant="rectangular" width={'100%'} height={30} />
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Skeleton variant="rectangular" width={'100%'} height={30} />
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Skeleton variant="rectangular" width={'100%'} height={300} />
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Typography>{formatPatientName(patient)}</Typography>
					<Grid container className="mt-1 mb-5" spacing={1}>
						<Grid item md={4} xs={12}>
							<GridTextField label="Hospital No." value={patient?.HospitalID ?? ''} InputLabelProps={{ shrink: true }} />
						</Grid>
						<Grid item md={4} xs={12}>
							<GridTextField
								label="DOB / Age / Gender"
								value={getPatientProfile(patient) ?? ''}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>
					<BaseTable
						items={medicalRecords}
						loading={loading}
						headers={[
							{
								label: '#',
								value: (item, index: number) => index + 1,
							},
							{
								label: 'Admission Date',
								value: (item: ViewMedicalHistory) => dateTimeFormat(item.AdmissionDate),
							},
							{
								label: 'Attending Physician',
								value: 'AttendingPhysician',
							},
							{
								label: 'Diagnosis',
								value: 'DischargeDiagnosis',
							},
							{
								label: 'Follow Up',
								value: (item: ViewMedicalHistory) => convertToSqlDate(item.Discharge_FollowupCheckupDate),
							},
							{
								label: 'Hospital No.',
								value: 'HospitalID',
							},
							{
								label: 'Case No.',
								value: 'CaseID',
							},
							{
								label: 'Service Type',
								value: 'SubServiceType',
							},
							{
								label: 'Attending Physician',
								value: 'AttendingPhysician',
							},
						]}
					/>
				</>
			)}
		</div>
	);
};

export default observer(ViewPatientMedicalRecord);
