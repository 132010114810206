import { makeAutoObservable } from 'mobx';
import { BreadcrumbProps } from '../components/navigation/Breadcrumbs';
import localforage from 'localforage';

class BreadcrumbsStore {
	items: BreadcrumbProps[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	update(items: BreadcrumbProps[]) {
		this.items = items;
		localforage.setItem('breadcrumbs', items);
	}

	change(index: number) {
		let filteredItems = [];
		for (let i = 0; i < this.items.length; i++) {
			if (i <= index) {
				filteredItems.push(this.items[i]);
			}
		}

		this.items = filteredItems;
		localforage.setItem('breadcrumbs', filteredItems);
	}
}

const store = new BreadcrumbsStore();
export default store;
