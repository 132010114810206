import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const IVFluidSheetPage = lazy(() => import('../pages/ViewPatient/pages/IVFluidSheet/IVFluidSheetPage'));
const ConsumedIVFluidsPage = lazy(() => import('../pages/ViewPatient/pages/IVFluidSheet/pages/ConsumedIVFluids/ConsumedIVFluidsPage'));
const IVFluidMonitoringPage = lazy(() => import('../pages/ViewPatient/pages/IVFluidSheet/pages/IVFluidMonitoring/IVFluidMonitoringPage'));

const ivFluidSheetRoutes: RouteObject = {
	path: 'iv-fluid-sheet',
	element: <IVFluidSheetPage />,
	children: [
		{ index: true, element: <IVFluidMonitoringPage /> },
		{ path: 'consumed-iv-fluids', element: <ConsumedIVFluidsPage /> },
	],
};

export default ivFluidSheetRoutes;
