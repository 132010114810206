import React, { useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { HFormTextFieldProps } from '@hybrain/mui.ui.final-form.h-form-text-field';
import { HFormDatePickerProps } from '@hybrain/mui.ui.final-form.h-form-date-picker';

import BaseForm from 'components/form/BaseForm';
import FormTextField from 'components/form/FormTextField';
import FormDatePicker from 'components/form/FormDatePicker';
import PatientStore from 'modules/private/stores/PatientStore';
import useBreadcrumbViewPatient from 'hooks/useBreadcrumbViewPatient';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { parseJSON } from 'helpers/utils/json';
import useApi from 'hooks/useApi';
import OutPatientComplexFormProps from 'interfaces/outpatient-complex-form.model';
import { Typography } from '@mui/material';
import PrintButton from 'components/inputs/buttons/PrintButton';
import usePrint from 'hooks/usePrint';
import OutPatientComplexFormPrint from './../components/OutPatientComplexFormPrint';
import AuthStore from 'modules/private/stores/AuthStore';

export const Table = styled('table')({
	borderCollapse: 'collapse',
	width: '100%',

	'& td': {
		borderLeft: '1px solid black',
	},

	'& td:last-of-type': {
		borderRight: '1px solid black',
	},

	'& tr:first-of-type': {
		borderTop: '1px solid black',
	},

	'& tr td': {
		borderBottom: '1px solid black',
	},
});

export const TableCell = styled('td')({
	padding: '5px 10px',
	height: 1,
	minHeight: 1,
});

const TextField = (props: HFormTextFieldProps) => <FormTextField variant="standard" {...props} />;
const DatePicker = (props: HFormDatePickerProps) => <FormDatePicker textFieldProps={{ variant: 'standard' }} {...props} />;

export const Label = (props: { children?: React.ReactNode }) => <span className="shrink-0">{props.children}</span>;

export interface OutPatientComplexFormPayload {
	PatientName: string;
	AgeSex: string;
	Birthdate: string;
	Date: string;
	Address: string;
	CivilStatus: string;
	Religion: string;
	ContactNumber: string;
	ChiefComplaint: string;
	BP: string;
	HR: string;
	RR: string;
	Temp: string;
	Weight: string;
	O2Sat: string;
	Covid19ImmunizationStatus: string;
	HistoryOfPresentIllness: string;
	PastMedicalHistory: string;
	FamilyHistory: string;
	PersonalAndSocialHistory: string;
	PhysicalExamination: string;
	Diagnosis: string;
	ICD10Code: string;
	DoctorsOrder: string;
	NurseNotes: string;
	NameAndSignature: string;
	AttendingPhysician: string;
	Consent: string;
}

interface OutPatientComplexFormPageProps {
	formType: 'Add' | 'Update';
}

const OutPatientComplexForm = ({ formType }: OutPatientComplexFormPageProps) => {
	const [currentFormType, setCurrentFormType] = useState<string>('Add');
	const printRef = useRef<any>(null);

	const { state, pathname } = useLocation() as { state: { outPatient: OutPatientComplexFormProps }; pathname: string };
	const formState = parseJSON(sessionStorage.getItem('form'));
	const navigate = useNavigate();
	useEffect(() => {
		if (!formState) {
			navigate(`/patients/view/reports`);
		}
	}, []);

	useEffect(() => {
		setCurrentFormType(formType);
	}, [formType]);
	useEffect(() => {
		//check if has state value, else change to Add
		if (!state) {
			setCurrentFormType('Add');
		}
	}, [currentFormType]);

	const outPatientComplex = useMemo(() => {
		if (state?.outPatient) {
			return parseJSON(state.outPatient.Details);
		} else {
			return {} as OutPatientComplexFormProps;
		}
	}, [state]);

	const { loading, callApi } = useApi({
		url: 'patientregistry/forms',
		login: true,
		method: 'POST',
		onSuccess({ data }) {
			setCurrentFormType('Update');
			navigate(pathname, { state: { outPatient: data?.response } });
		},
	});

	const { loading: loadingUpdate, callApi: callUpdateApi } = useApi({
		onBeforeApiCall: () => {
			return formState.EHRPatientRegFormsID || state.outPatient.EHRPatientRegFormsID || false;
		},
		url: (id) => `patientregistry/forms/${id}`,
		login: true,
		method: 'PATCH',
		onSuccess({ data }) {
			navigate(pathname, { state: { outPatient: data?.response } });
		},
	});

	const submitForm = async (payloadDetails: any) => {
		let payload: any = {
			Details: JSON.stringify(payloadDetails),
		};

		if (currentFormType == 'Update') {
			payload.UpdatedBy = `${AuthStore.user.employee.FirstName} ${AuthStore.user.employee.LastName}`;
			await callUpdateApi(payload);
		} else {
			payload.PatientRegID = PatientStore.patient.PatientRegID;
			payload.EHRMaintenanceFormsID = formState.EHRMaintenanceFormsID;
			payload.TransactionDate = new Date();
			payload.isActive = 1;
			await callApi(payload);
		}
	};

	const type = pathname.split('/');
	const typeLabel = type.pop();
	const capitalize = typeLabel ? typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1) : currentFormType;

	useBreadcrumbViewPatient([
		{ label: 'Reports', path: '/patients/view/reports' },
		{ label: 'Outpatient Complex Form', path: '/patients/view/reports/outpatient-complex-form' },
		{ label: capitalize },
	]);

	const handlePrint = usePrint({
		content: () => printRef.current,
		fontSize: 10,
	});

	return (
		<div>
			<div className="hidden">
				<div ref={printRef}>{<OutPatientComplexFormPrint reportForm={outPatientComplex} />}</div>
			</div>
			{currentFormType === 'Update' && <div className="text-right mb-2">{<PrintButton onClick={handlePrint} />}</div>}

			<BaseForm
				initialValues={{
					PatientName: PatientStore.patient.FullName,
					AgeSex: PatientStore.patient.Age + '/' + PatientStore.patient.Gender,
					Birthdate: PatientStore.patient.Birthdate,
					CivilStatus: PatientStore.patient.CivilStatus,
					Religion: PatientStore.patient.Religion,
					ContactNumber: PatientStore.patient.ContactNo?.[0],
					...outPatientComplex,
				}}
				onSubmit={submitForm}
				loading={loading || loadingUpdate}
				className="mt-4"
			>
				<div className="text-lg text-center mb-2">OUTPATIENT COMPLEX FORM</div>
				<Box sx={{ height: 3, backgroundColor: 'black', marginBottom: 2 }}></Box>

				<Grid container spacing={2}>
					<Grid item xs={12} md={5} className="flex gap-2">
						<Label>Patient's Name:</Label>
						<TextField name="PatientName" />
					</Grid>
					<Grid item xs={12} md={3} className="flex gap-2">
						<Label>Age/Sex:</Label>
						<TextField name="AgeSex" />
					</Grid>
					<Grid item xs={12} md={4} className="flex gap-2">
						<Label>Date:</Label>
						<DatePicker name="Date" />
					</Grid>

					<Grid item xs={12} md={5} className="flex gap-2">
						<Label>Address:</Label>
						<TextField name="Address" />
					</Grid>
					<Grid item xs={12} md={5} className="flex gap-2">
						<Label>Contact Number:</Label>
						<TextField name="ContactNumber" />
					</Grid>

					<Grid item xs={12} md={5} className="flex gap-2">
						<Label>Date of Birth (mm/dd/yyyy):</Label>
						<DatePicker name="Birthdate" />
					</Grid>
					<Grid item xs={12} md={3} className="flex gap-2">
						<Label>Civil Status:</Label>
						<TextField name="CivilStatus" />
					</Grid>
					<Grid item xs={12} md={4} className="flex gap-2">
						<Label>Religion:</Label>
						<TextField name="Religion" />
					</Grid>
				</Grid>
				<Box sx={{ height: 3, backgroundColor: 'black', marginTop: '20px' }}></Box>

				<Grid container spacing={2} className="mt-4">
					<Grid item xs={12} md={5} className="flex gap-2">
						<Label>Chief Complaint/s: :</Label>
						<TextField name="ChiefComplaint" />
					</Grid>
				</Grid>

				<div className="mt-3 mb-3 flex justify-between">
					<div>Vital Signs:</div>

					<div className="flex gap-1">
						<div>BP:</div>
						<TextField name="BP" />
						<div>mmHg</div>
					</div>
					<div className="flex gap-1">
						<div>HR:</div>
						<TextField name="HR" />
						<div>bpm</div>
					</div>
					<div className="flex gap-1">
						<div>RR:</div>
						<TextField name="RR" />
						<div>cpm</div>
					</div>
					<div className="flex gap-1">
						<div>T:</div>
						<TextField name="Temp" />
						<div>&#8451;</div>
					</div>
					<div className="flex gap-1">
						<div>Weight:</div>
						<TextField name="Weight" />
						<div>kg</div>
					</div>
					<div className="flex gap-1">
						<Label>O2 Sat:</Label>
						<TextField name="O2Sat" />
						<div>%</div>
					</div>
				</div>

				<Table>
					<tbody>
						<tr>
							<TableCell>
								<div>Covid-19 Immunization Status:</div>
								<TextField name="Covid19ImmunizationStatus" />

								<div className="mt-2">History of Present Illness:</div>
								<TextField name="HistoryOfPresentIllness" multiline minRows={4} />

								<div className="mt-2">Past Medical History:</div>
								<TextField name="PastMedicalHistory" multiline minRows={4} />

								<div className="mt-2">Family History:</div>
								<TextField name="FamilyHistory" multiline minRows={4} />

								<div className="mt-2">Personal and Social History:</div>
								<TextField name="PersonalAndSocialHistory" multiline minRows={4} />
							</TableCell>
							<TableCell sx={{ verticalAlign: 'top' }}>
								<div>Physical Examination:</div>
								<TextField name="PhysicalExamination" multiline minRows={10} />
							</TableCell>
						</tr>
						<tr>
							<TableCell>
								<div>Diagnosis:</div>
								<TextField name="Diagnosis" multiline minRows={4} />
							</TableCell>
							<TableCell>
								<div>ICD10 Code:</div>
								<TextField name="ICD10Code" multiline minRows={4} />
							</TableCell>
						</tr>
						<tr>
							<TableCell colSpan={2}>
								<div>Doctor's Order:</div>
								<TextField name="DoctorsOrder" multiline minRows={6} />
							</TableCell>
						</tr>
						<tr>
							<TableCell colSpan={2}>
								<div>Nurse's Notes:</div>
								<TextField name="NurseNotes" multiline minRows={4} />

								<div className="flex justify-end mt-3">
									<Label>Name & Signature:</Label>
									<TextField name="NameAndSignature" sx={{ width: 250, marginLeft: 1 }} />
								</div>
							</TableCell>
						</tr>
						<tr>
							<TableCell>
								<div>Consent:</div>
								<TextField name="Consent" />
								<div className="text-center">Patient’s/Guardian’s Signature Over Printed Name/ Date</div>
							</TableCell>
							<TableCell>
								<div>Attending Physician:</div>
								<div className="flex">
									<TextField name="AttendingPhysician" sx={{ width: 300 }} />
									<Label>, M.D.</Label>
								</div>
							</TableCell>
						</tr>
					</tbody>
				</Table>
			</BaseForm>
		</div>
	);
};

export default observer(OutPatientComplexForm);
