import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

type PageProps = {
	noPadding?: boolean;
} & PaperProps;

const Root = styled(Paper)({
	borderRadius: 10,
});

const Page = ({ children, className, noPadding, title, ...props }: PageProps) => {
	return (
		<Root className={clsx(className, { 'p-4': !noPadding }, 'flex-grow')} elevation={3} {...props}>
			{title && (
				<Typography variant="h4" fontWeight={600} sx={{ marginBottom: 5 }}>
					{title}
				</Typography>
			)}
			{children}
		</Root>
	);
};

export default Page;
