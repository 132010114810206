import { hUseApi, HUseApiProps } from '@hybrain/mui.hooks.use-api';
import Patient from 'interfaces/patient.model';
import localforage from 'localforage';
import PatientStore from 'modules/private/stores/PatientStore';

const useApiPatientReg = <T extends any = any>(
	{ onBeforeApiCall, ...props }: HUseApiProps<T, typeof PatientStore.patient.PatientRegID>,
	deps?: React.DependencyList
) => {
	return hUseApi<T, typeof PatientStore.patient.PatientRegID>(
		{
			...props,
			onBeforeApiCall: async () => {
				const patient: Patient = (await localforage.getItem('patient')) ?? ({} as Patient);
				if (typeof onBeforeApiCall?.() === 'boolean') {
					return onBeforeApiCall?.();
				}

				return patient.PatientRegID;
			},
		},
		deps ? deps : []
	);
};

export default useApiPatientReg;
