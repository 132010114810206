import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const EditButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton startIcon={<BorderColorIcon />} {...props}>
			{children}
		</BaseButton>
	);
};

export default EditButton;
