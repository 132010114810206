import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const PublicLayout = lazy(() => import('../layout/PublicLayout'));
const Login = lazy(() => import('../pages/Login'));

const route: RouteObject = {
	path: '/',
	element: <PublicLayout />,
	children: [{ path: 'login', element: <Login /> }],
};

export default route;
