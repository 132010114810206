import React, { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import useBreadcrumbViewPatient from 'hooks/useBreadcrumbViewPatient';
import BaseTable from 'components/data-display/BaseTable';
import useApiPatientReg from 'hooks/useApiPatientReg';
import EditButton from 'components/inputs/buttons/EditButton';
import { PATIENT_TRANSACTION_TABLE_WIDTH } from 'helpers/constants/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseJSON } from 'helpers/utils/json';
import OutPatientComplexForm from 'interfaces/outpatient-complex-form.model';
import { OutPatientComplexFormPayload } from './pages/OutPatientComplexForm';

export const Table = styled('table')({
	borderCollapse: 'collapse',
	width: '100%',

	'& td': {
		borderLeft: '1px solid black',
	},

	'& td:last-of-type': {
		borderRight: '1px solid black',
	},

	'& tr:first-of-type': {
		borderTop: '1px solid black',
	},

	'& tr td': {
		borderBottom: '1px solid black',
	},
});

export const TableCell = styled('td')({
	padding: '5px 10px',
	height: 1,
	minHeight: 1,
});

export const Label = (props: { children?: React.ReactNode }) => <span className="shrink-0">{props.children}</span>;

const OutPatientComplexPage = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const localstorage = parseJSON(sessionStorage.getItem('form'));
	useBreadcrumbViewPatient([
		{ label: 'Reports', path: '/patients/view/reports' },
		{ label: 'Outpatient Complex Form', path: '/patients/view/reports/outpatient-complex-form' },
	]);

	const formState = useMemo(() => {
		if (state?.outPatient) {
			return parseJSON(state.outPatient.Details);
		} else if (localstorage) {
			return localstorage;
		} else {
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!formState) {
			navigate(`/patients/view/reports`);
		}
	}, [formState]);

	const { data: outpatientComplexData, loading } = useApiPatientReg<OutPatientComplexForm[]>({
		onBeforeApiCall: () => {
			if (!formState?.EHRMaintenanceFormsID) return false;
		},
		url: (patientRegID) =>
			`patientregistry/forms/patient/forms/list?PatientRegID=${patientRegID}&FormID=${formState.EHRMaintenanceFormsID}`,
	});

	const handleClickViewBtn = (outPatient: OutPatientComplexForm) => {
		sessionStorage.setItem('form', JSON.stringify(outPatient));
		navigate(`/patients/view/reports/outpatient-complex-form/update`, {
			state: {
				outPatient,
			},
		});
	};

	return (
		<div>
			<EditButton
				onClick={() =>
					navigate('/patients/view/reports/outpatient-complex-form/add', {
						state: formState,
					})
				}
			>
				Add OCF
			</EditButton>

			<BaseTable
				className="mt-4"
				items={outpatientComplexData}
				loading={loading}
				maxWidth={PATIENT_TRANSACTION_TABLE_WIDTH}
				headers={[
					{ label: '#', value: 'EHRPatientRegFormsID' },
					{ label: 'Date', value: 'CreatedDateTime', type: 'date' },
					{ label: 'Encoded by', value: 'CreatedBy' },
					{ label: 'Chief Complaint', value: (item) => parseJSON<OutPatientComplexFormPayload>(item.Details)?.ChiefComplaint },
				]}
				actionColumn={(item) => <EditButton onClick={() => handleClickViewBtn(item)}>Edit</EditButton>}
			/>
		</div>
	);
};

export default observer(OutPatientComplexPage);
