import React from 'react';
import FilterButton from './buttons/FilterButton';
import FormDatePicker from 'components/form/FormDatePicker';
import { minusDays } from 'helpers/utils/date';
import { HForm, HFormProps } from '@hybrain/mui.ui.final-form.h-form';

type DateFilterInputGroupProps = {} & HFormProps;

const DateFilterInputGroup: React.FC<DateFilterInputGroupProps> = ({ initialValues, ...props }) => {
	return (
		<HForm
			className="flex items-end gap-x-2"
			initialValues={{ dateFrom: minusDays(new Date(), 15), dateTo: new Date(), ...initialValues }}
			noSubmitButton
			{...props}
		>
			<div>
				<div>Filter Date</div>
				<div className="flex items-center gap-x-2">
					<FormDatePicker name="dateFrom" />
					<div>-</div>
					<FormDatePicker name="dateTo" />
				</div>
			</div>

			<FilterButton type="submit" />
		</HForm>
	);
};

export default React.memo(DateFilterInputGroup, (prevProps, nextProps) => {
	return true;
});
