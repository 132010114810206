import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import OutPatientComplexForm from '../pages/ViewPatient/pages/Reports/pages/OutPatientComplexForm/pages/OutPatientComplexForm';

const ViewPatientReportsPage = lazy(() => import('../pages/ViewPatient/pages/Reports/ReportsPage'));

const ReportsDashboardPage = lazy(() => import('../pages/ViewPatient/pages/Reports/pages/ReportsDashboardPage'));
const ArtificialKidneyUnitPage = lazy(
	() => import('../pages/ViewPatient/pages/Reports/pages/ArtificialKidneyUnit/ArtificialKidneyUnitPage')
);
const OutPatientComplexPage = lazy(
	() => import('../pages/ViewPatient/pages/Reports/pages/OutPatientComplexForm/OutPatientComplexPage')
);
const ERConsentFormPage = lazy(() => import('../pages/ViewPatient/pages/Reports/pages/ERConsentForm/ERConsentFormPage'));
const ERPatientRequestPage = lazy(() => import('../pages/ViewPatient/pages/Reports/pages/ERPatientRequest/ERPatientRequestPage'));
const ERTriagePage = lazy(() => import('../pages/ViewPatient/pages/Reports/pages/ERTriage/ERTriagePage'));
const FaceSheetPage = lazy(() => import('../pages/ViewPatient/pages/Reports/pages/FaceSheet/FaceSheetPage'));
const PreoperativeRiskStratificationFormPage = lazy(
	() =>
		import('../pages/ViewPatient/pages/Reports/pages/PreoperativeRiskStratificationForm/PreoperativeRiskStratificationFormPage')
);

const reportRoutes: RouteObject = {
	path: 'reports',
	element: <ViewPatientReportsPage />,
	children: [
		{ index: true, element: <ReportsDashboardPage /> },
		{ path: 'hemodialysis-checklist', element: <ArtificialKidneyUnitPage /> },
		{
			path: 'outpatient-complex-form',
			children: [
				{ index: true, element: <OutPatientComplexPage /> },
				{ path: 'add', element: <OutPatientComplexForm formType="Add" /> },
				{ path: 'update', element: <OutPatientComplexForm formType="Update" /> },
			],
		},
		{ path: 'er-consent-form', element: <ERConsentFormPage /> },
		{ path: 'er-patient-request', element: <ERPatientRequestPage /> },
		{ path: 'er-triage', element: <ERTriagePage /> },
		{ path: 'facesheet', element: <FaceSheetPage /> },
		{ path: 'preoperative-risk-stratification-form', element: <PreoperativeRiskStratificationFormPage /> },
	],
};

export default reportRoutes;
