import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BaseButton, { BaseButtonProps } from './BaseButton';

const ProceedButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton startIcon={<ArrowForwardIcon />} type="submit" {...props}>
			{children ?? 'PROCEED'}
		</BaseButton>
	);
};

export default ProceedButton;
