import api from 'api';

export interface FilterOptions {
	dateFrom: { AdmissionDate: string };
	dateTo: { AdmissionDate: string };
}

export const getMedicalHistoryDashboard = (payload?: FilterOptions) => {
	return api().get('patientregistry/medical-history-dashboard', {params: payload});
};

export const getPatientMedicalHistory = (patientID: number) => {
	return api().get(`patientregistry/${patientID}/medical-history`);
}