import React from 'react';

import { getFullName } from 'helpers/utils/string';
import { dateTimeFormat } from 'helpers/utils/date';
import PatientStore from 'modules/private/stores/PatientStore';

import TextPlaceholder from '../TextPlaceholder';
import SignatureLayout from '../SignatureLayout';
import ConsentFormLayout from '../ConsentFormLayout';
import { CadaverForm } from '../../pages/CadaverFormPage';

interface CadaverFormPrintProps {
	form: CadaverForm;
}

const CadaverFormPrint: React.FC<CadaverFormPrintProps> = ({ form }) => {
	return (
		<ConsentFormLayout title="RELEASE OF CADAVER FORM">
			<div>
				I, <TextPlaceholder text={form.ARName} /> of legal age and a resident of <TextPlaceholder text={form.ARAddress} /> hereby
				received the dead body of my <TextPlaceholder text={form.ARRelationship} /> by the name of{' '}
				<TextPlaceholder text={getFullName(PatientStore.patient)} /> who died in this hospital on{' '}
				<TextPlaceholder text={dateTimeFormat(form.ReleasedDateTime)} />.
			</div>

			<SignatureLayout
				className="mt-[5mm]"
				alignItems="flex-end"
				justifyContent="space-between"
				items={[
					{
						label: 'Printed Name and Signature of Claimant',
						signature: form.ARSignature,
						value: form.ARName,
						width: '80mm',
					},
					{ label: 'Relationship to Deceased Patient', width: '80mm', value: form.ARRelationship },
				]}
			/>

			<div className="mt-[4mm]">Prepared by:</div>

			<SignatureLayout
				alignItems="flex-end"
				justifyContent="space-between"
				items={[
					{ label: 'Name of Nurse on Duty', value: form.NurseOnDuty, width: '80mm', signature: form.NurseSignature },
					{
						label: 'Date and Time',
						value: dateTimeFormat(form.PreparedDate),
						width: '65mm',
						textPlaceHolderProps: { minWidth: '60mm' },
					},
				]}
			/>

			<div className="mt-[4mm]">Released by:</div>

			<SignatureLayout
				alignItems="flex-end"
				justifyContent="space-between"
				items={[
					{
						label: 'Printed Name and Signature of Security Personnel',
						value: form.SecurityPersonnel,
						signature: form.SecurityPersonnelSignature,
						width: '100mm',
						textPlaceHolderProps: { minWidth: '90mm' },
					},
					{
						label: 'Date and Time',
						width: '65mm',
						value: dateTimeFormat(form.ReleasedDateTime),
						textPlaceHolderProps: { minWidth: '60mm' },
					},
				]}
			/>
		</ConsentFormLayout>
	);
};

export default CadaverFormPrint;
