import React from 'react';
import clsx from 'clsx';
import TextPlaceholder, { TextPlaceholderProps } from './TextPlaceholder';
import { styled } from '@mui/material/styles';

type AlignItemsProperty = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
type JusifyContentProperty = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

interface SignatureLayoutProps {
	className?: string;
	items?: {
		label?: string;
		value: any;
		signature?: any;
		secondaryText?: string;
		minWidth?: string | number;
		textPlaceHolderProps?: TextPlaceholderProps;
		italic?: boolean;
		italicSecondary?: boolean;
		className?: string;
		width?: string | number;
	}[];
	alignItems?: AlignItemsProperty;
	justifyContent?: JusifyContentProperty;
	children?: React.ReactNode;
}

const Root = styled('div', {
	shouldForwardProp: (prop: string) => !['alignItems', 'justifyContent'].includes(prop),
})<{ alignItems?: AlignItemsProperty; justifyContent?: JusifyContentProperty }>(({ alignItems, justifyContent }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: justifyContent ?? 'space-between',
	textAlign: 'center',
	margin: '-1.3rem -1rem',
	alignItems,

	'@media print': {
		margin: '-2mm',
	},
}));

const Item = styled('div')<{ width?: string | number }>(({ width }) => ({
	padding: '1.3rem 1rem',
	width: width ?? '50%',

	'@media print': {
		padding: '2mm',
	},
}));

const Signature = styled('img')({
	height: '5rem',

	'@media print': {
		height: '9mm',
	},
});

const SignatureLayout = ({ className, items, alignItems, children, justifyContent }: SignatureLayoutProps) => {
	return (
		<div className={className}>
			<Root alignItems={alignItems} justifyContent={justifyContent}>
				{items?.map((item, index) => (
					<Item key={index} className={item.className} width={item.width}>
						<TextPlaceholder
							text={
								<div>
									{item.signature && (
										<div>
											<Signature src={item.signature} alt="" />
										</div>
									)}
									<div>{item.value}</div>
								</div>
							}
							minWidth={item.minWidth}
							{...item.textPlaceHolderProps}
						/>
						{item.label && <div className={clsx('font-bold', { italic: item.italic })}>{item.label}</div>}
						{item.secondaryText && <div className={clsx({ italic: item.italic || item.italicSecondary })}>{item.secondaryText}</div>}
					</Item>
				))}

				{children}
			</Root>
		</div>
	);
};

export default SignatureLayout;
