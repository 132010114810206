import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			dark: process.env.REACT_APP_PRIMARY_COLOR_DARK ?? '#002f80',
			main: process.env.REACT_APP_PRIMARY_COLOR_MAIN ?? '#003DA7',
			light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT ?? '#004bcc',
			// dark: '#013203',
			// main: '#025905',
			// light: '#037d07',
		},
	},
	shape: {
		borderRadius: 7,
	},
	typography: {
		fontFamily: 'Poppins',
		fontSize: 11,
	},
});

export default theme;
