import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterButton: React.FC<BaseButtonProps> = ({ children, ...props }) => {
	return (
		<BaseButton startIcon={<FilterAltIcon />} {...props}>
			{children ?? 'FILTER'}
		</BaseButton>
	);
};

export default FilterButton;
