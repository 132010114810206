import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormSignaturePad from 'components/form/FormSignaturePad';
import GridFormSelect from 'components/form/grid/GridFormSelect';
import GridFormTextField from 'components/form/grid/GridFormTextField';
import useApi from 'hooks/useApi';
import { getRelationshipList } from 'services/maintenance/maintenance.service';
import Relationship from 'interfaces/relationship.model';

export interface WitnessAuthorizedPersonnelFormProps {
	ARName: string;
	ARAddress: string;
	ARRelationship: string;
	ARSignature: string;
}

interface WitnessAuthorizedPersonnelComponentProps {
	title?: string;
	children?: React.ReactNode;
}

const WitnessAuthorizedPersonnelComponent = ({ title, children }: WitnessAuthorizedPersonnelComponentProps) => {
	const { data: relationships, loading: loadingRelationships } = useApi<Relationship[]>({
		action: getRelationshipList,
		immediate: true,
	});

	return (
		<Paper className="p-3 mt-3" elevation={4}>
			<div className="text-lg font-bold mb-2">{title ?? 'Witness/Authorized Personnel'}</div>

			<Grid container spacing={1}>
				<GridFormTextField name="ARName" label="Name of Authorized Representative" />
				<GridFormTextField name="ARAddress" label="Address of Authorized Representative" multiline minRows={4} />
				<GridFormSelect
					name="ARRelationship"
					label="Relation to Patient"
					options={relationships}
					optionText="Description"
					optionValue="Description"
					loading={loadingRelationships}
				/>

				<Grid item xs={12}>
					<FormSignaturePad name="ARSignature" />
				</Grid>

				{children}
			</Grid>
		</Paper>
	);
};

export default WitnessAuthorizedPersonnelComponent;
