import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const OxygenIntakePage = lazy(() => import('../pages/ViewPatient/pages/OxygenIntake/OxygenIntakePage'));
const OxygenIntakeRecordsPage = lazy(
	() => import('../pages/ViewPatient/pages/OxygenIntake/pages/OxygenIntakeRecords/OxygenIntakeRecordsPage')
);
const OxygenIntake24hrPage = lazy(
	() => import('../pages/ViewPatient/pages/OxygenIntake/pages/OxygenIntake24hr/OxygenIntake24hrPage')
);

const oxygenIntakeSheetRoutes: RouteObject = {
	path: 'oxygen-intake',
	element: <OxygenIntakePage />,
	children: [
		{ index: true, element: <OxygenIntake24hrPage /> },
		{ path: 'records', element: <OxygenIntakeRecordsPage /> },
	],
};

export default oxygenIntakeSheetRoutes;
