import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import vewPatientRoutes from './view-patient-routes';
import RecordsPage from '../pages/Records/RecordsPage';
import MedicalHistoryDashboard from '../pages/Records/MedicalHistoryDashboard';
import ViewPatientRecords from '../pages/Records/ViewPatientMedicalRecord';

const PrivateLayout = lazy(() => import('../layout/PrivateLayout'));
const HomePage = lazy(() => import('../pages/Home/HomePage'));
const PatientsPage = lazy(() => import('../pages/Patients/PatientsPage'));

const DoctorPage = lazy(() => import('../pages/Doctor/DoctorPage'));
const DMPage = lazy(() => import('../pages/DM/DMPage'));
const SMPage = lazy(() => import('../pages/SM/SMPage'));
const ReportsPage = lazy(() => import('../pages/Reports/ReportsPage'));
const MaintenancePage = lazy(() => import('../pages/Maintenance/MaintenancePage'));
const PatientProfilePage = lazy(() => import('../pages/PatientProfile/PatientProfile'));

const route: RouteObject = {
	path: '/',
	element: <PrivateLayout />,
	children: [
		{ index: true, element: <HomePage /> },
		{
			path: 'patients',
			children: [{ index: true, element: <PatientsPage /> }, vewPatientRoutes],
		},
		{ path: 'doctor', element: <DoctorPage /> },
		{
			path: 'records',
			element: <RecordsPage />,
			children: [
				{ index: true, element: <MedicalHistoryDashboard /> },
				{
					path: 'view',
					element: <ViewPatientRecords />,
				},
			],
		},
		{ path: 'dm', element: <DMPage /> },
		{ path: 'sm', element: <SMPage /> },
		{ path: 'reports', element: <ReportsPage /> },
		{ path: 'maintenance', element: <MaintenancePage /> },
		{ path: 'patient-profile', element: <PatientProfilePage /> },
	],
};

export default route;
