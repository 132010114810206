import Corporation from 'interfaces/corporation.model';
import { makeAutoObservable } from 'mobx';

class CorporationStore {
	corporation = {} as Corporation;

	constructor() {
		makeAutoObservable(this);
	}

	setCorporation(corporation: Corporation) {
		this.corporation = corporation;
	}
}

const store = new CorporationStore();
export default store;
