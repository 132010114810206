import React from 'react';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
import PrintIcon from '@mui/icons-material/Print';
import BaseButton from 'components/inputs/buttons/BaseButton';
import EditButton from 'components/inputs/buttons/EditButton';
import BackButton from 'components/inputs/buttons/BackButton';

interface FormTemplateLayoutProps {
	label: string;
	handlePopulateData?: () => void;
	handleSaveAsPDF?: () => void;
	handlePrint?: () => void;
	children?: React.ReactNode;
}

const FormTemplateLayout = ({ label, children, handlePopulateData, handlePrint, handleSaveAsPDF }: FormTemplateLayoutProps) => {
	return (
		<>
			<div className="flex gap-x-2">
				<EditButton onClick={handlePopulateData}>Populate Data</EditButton>
				{/* <BaseButton startIcon={<UploadFileIcon />} onClick={handleSaveAsPDF}>
					Save as PDF
				</BaseButton> */}
				<BaseButton startIcon={<PrintIcon />} onClick={handlePrint}>
					Print Preview
				</BaseButton>

				<div className="flex-grow"></div>
				<BackButton path="/patients/view/consents" />
			</div>

			<div className="text-lg font-bold mt-4">{label}</div>
			<div className="mt-3"></div>

			<div className="text-[14px]">{children}</div>
		</>
	);
};

export default FormTemplateLayout;
