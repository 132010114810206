import { getAge } from 'helpers/utils/date';
import { formatCurrency } from 'helpers/utils/numbers';
import { getFullName } from 'helpers/utils/string';
import PatientRegistry from 'interfaces/patient-registry.model';
import Patient from 'interfaces/patient.model';
import { makeAutoObservable } from 'mobx';

class PatientStore {
	patient: Patient = {} as Patient;
	patientRegistry: PatientRegistry = {} as PatientRegistry;

	constructor() {
		makeAutoObservable(this);
	}

	setPatient(patient: Patient) {
		this.patient = {
			...patient,
			FullName: getFullName(patient),
			Age: getAge(patient.Birthdate),
			BMI: this.calculateBMI(patient.Weight, patient.Height),
		};
	}

	setPatientRegistry(patientRegistry: PatientRegistry) {
		this.patientRegistry = patientRegistry;
	}

	calculateBMI(weight: number, height: number) {
		if (!height) return 0;
		height = height / 100; //convert centimeters to meters
		return Number(formatCurrency(weight / (height * height)));
	}
}
const store = new PatientStore();

export default store;
