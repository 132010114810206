import React from 'react';
import Grid, { GridProps, GridSize } from '@mui/material/Grid';
import { HFormDateTimePickerProps } from '@hybrain/mui.ui.final-form.h-form-date-time-picker';
import FormDateTimePicker from '../FormDateTimePicker';

type GridFormDateTimePickerProps = {
	gridProps?: GridProps;
	xl?: boolean | GridSize | undefined;
	lg?: boolean | GridSize | undefined;
	md?: boolean | GridSize | undefined;
	xs?: boolean | GridSize | undefined;
} & HFormDateTimePickerProps;

const GridFormDateTimePicker = ({ className, gridProps, xl, lg, md, xs, ...props }: GridFormDateTimePickerProps) => {
	return (
		<Grid item xs={xs ?? 12} md={md} lg={lg} xl={xl} className={className} {...gridProps}>
			<FormDateTimePicker {...props} />
		</Grid>
	);
};

export default GridFormDateTimePicker;
