import api from 'api';

export const getVaccineTypesList = () => {
	return api().get('patientregistry/vaccination/vaccinetypes/list');
};

export const getPatientDoctorsList = (patientRegID: number) => {
	return api().get(`patientregistry/doctor/patient/${patientRegID}`);
};

export const getFrequenciesList = () => {
	return api().get('frequency');
};

export const getAdministrationRoutesList = () => {
	return api().get('administration-route');
};

export const getSubDepartmentList = () => {
	return api().get('subdepartments/viewWithAll/0');
};

export const getRelationshipList = () => {
	return api().get('maintenance/relationship');
};

export const getCorporation = () => {
	return api().get('maintenance/corporations');
};

export const getPharmacies = () => {
	return api().get('maintenance/pharmacy-subdepartment');
};
